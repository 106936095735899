import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import categoryFilter from './modules/categoryFilter'
import categorySort from './modules/categorySort'
import menu from './modules/menu'
import cart from './modules/cart'
import account from './modules/account'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        app,
        categoryFilter,
        categorySort,
        menu,
        cart,
        account
    },
    strict: debug,
    plugins: [vuexLocal.plugin],
})
