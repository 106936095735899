import store from ".."

const state = () => ({
    isVisible: false
})

// Getters
const getters = {
}

// Actions
const actions = {
    close({ commit, state }) {
        commit('setIsVisible', false)
    },
    open({ commit, state }) {
        commit('setIsVisible', true)
    }
}

// Mutations
const mutations = {
    setIsVisible(state, value) {
        state.isVisible = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
