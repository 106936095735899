import store from ".."

const state = () => ({
    materials: [],
    isVisible: false
})

// Getters
const getters = {
    materials: (state, getters) => {
        return state.materials
    }
}

// Actions
const actions = {
    applyFilter({ commit, state }, materials) {
        commit('setMaterials', materials)
    },
    close({ commit, state }) {
        commit('setIsVisible', false)
    },
    open({ commit, state }) {
        commit('setIsVisible', true)
    }
}

// Mutations
const mutations = {
    setMaterials(state, materials) {
        state.materials = materials
    },
    setIsVisible(state, value) {
        state.isVisible = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
