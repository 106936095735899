import store from ".."

const state = () => ({
    property: '',
    direction: 'desc',
    isVisible: false
})

// Getters
const getters = {
    materials: (state, getters) => {
        return state.materials
    }
}

// Actions
const actions = {
    applyFilter({ commit, state }, { property, direction }) {
        commit('setProperty', property)
        commit('setDirection', direction)
    },
    close({ commit, state }) {
        commit('setIsVisible', false)
    },
    open({ commit, state }) {
        commit('setIsVisible', true)
    }
}

// Mutations
const mutations = {
    setProperty(state, property) {
        state.property = property
    },
    setDirection(state, direction) {
        state.direction = direction
    },
    setIsVisible(state, value) {
        state.isVisible = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
