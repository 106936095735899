// Import our CSS
import 'swiper/css/swiper.css'
import styles from '../scss/app.scss';

//Import vue components
import { mapState, mapGetters } from 'vuex'
import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper';
import axios from 'axios'

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    //Enable swiper
    Vue.use(VueAwesomeSwiper);

    // Create our vue instance
    // @ts-ignore
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        store,
        components: {
            'AddressForm': () => import(/* webpackChunkName: "AddressForm" */ './components/AddressForm.vue'),
            'AddToCart': () => import(/* webpackChunkName: "AddToCart" */ './components/AddToCart.vue'),
            'Basket': () => import(/* webpackChunkName: "Basket" */ './components/Basket.vue'),
            'Cart': () => import(/* webpackChunkName: "Cart" */ './components/Cart.vue'),
            'CategoryFilter': () => import(/* webpackChunkName: "CategoryFilter" */ './components/CategoryFilter.vue'),
            'CategorySort': () => import(/* webpackChunkName: "CategorySort" */ './components/CategorySort.vue'),
            'CategoryGrid': () => import(/* webpackChunkName: "CategoryGrid" */ './components/CategoryGrid.vue'),
            'ChangePassword': () => import(/* webpackChunkName: "ChangePassword" */ './components/ChangePassword.vue'),
            'Checkout': () => import(/* webpackChunkName: "Checkout" */ './components/Checkout.vue'),
            'EntriesGrid': () => import(/* webpackChunkName: "EntriesGrid" */ './components/EntriesGrid.vue'),
            'Instagramfeed': () => import(/* webpackChunkName: "Instagramfeed" */ './components/Instagramfeed.vue'),
            'Login': () => import(/* webpackChunkName: "Login" */ './components/Login.vue'),
            'MapSlidein': () => import(/* webpackChunkName: "MapSlidein" */ './components/MapSlidein.vue'),
            'PreviousOrder': () => import(/* webpackChunkName: "PreviousOrder" */ './components/PreviousOrder.vue'),
            'Search': () => import(/* webpackChunkName: "Search" */ './components/Search.vue'),
            'SiteMenu': () => import(/* webpackChunkName: "SiteMenu" */ './components/SiteMenu.vue'),
            'SlideInOverlay': () => import(/* webpackChunkName: "SlideInOverlay" */ './components/SlideInOverlay.vue'),
            'Tab': () => import(/* webpackChunkName: "Tab" */ './components/Tab.vue'),
            'Tabs': () => import(/* webpackChunkName: "Tabs" */ './components/Tabs.vue'),
            'NewsletterSignup': () => import(/* webpackChunkName: "NewsletterSignup" */ './components/NewsletterSignup.vue'),
        },
        mixins: [],
        data() {
            return {
                scroll_position: 0,
                scroll_direction: '',
            }
        },
        props: {
        },
        methods: {
            handleClick(event, ref) {
                console.log(event)
                console.log(this.$refs[ref].$swiper)
                let slider = this.$refs[ref].$swiper;
                slider.slideTo(event)
            },
            slideTo(event, ref) {
                let slider = this.$refs[ref].$swiper;
                slider.slideTo(event.activeIndex)
            },
            openFilter() {
                store.dispatch('categoryFilter/open')
            },
            closeFilter() {
                store.dispatch('categoryFilter/close')
            },
            openMenu() {
                store.dispatch('menu/open')
            },
            openCart() {
                store.dispatch('cart/open')
            },
            toggleMobileElement(e) {
                e.target.parentNode.classList.toggle('open')
            },
            showOrder(orderNumber) {
                store.dispatch('account/showOrder', orderNumber)
            },
            openLogin() {
                store.dispatch('account/openLogin')
            },
            openSorter() {
                store.dispatch('categorySort/open')
            },
            openMap() {
                store.commit('app/setMapVisibility', true)
            },
            handleScroll: function (event) {
                this.scroll_direction = (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
                this.scroll_position = (document.body.getBoundingClientRect()).top;

                const app = document.getElementById('app')
                let scrollPosY = window.pageYOffset | document.body.scrollTop;
                let scrollUp = 0

                let box = document.querySelector('.l-header');
                let height = box.offsetHeight - 47;

                if (app) {
                    if (this.scroll_direction === 'down') {
                        if (scrollPosY >= height) {
                            if (!app.classList.contains('scrolled')) {
                                app.classList.add('scrolled')
                            }
                            if (app.classList.contains('scrolled-up')) {
                                app.classList.remove('scrolled-up')
                            }
                            else if (scrollPosY < height) {
                                app.classList.remove('scrolled')
                            }
                        }
                        if (scrollPosY >= 76) {
                            if (!app.classList.contains('scrolled--mobile')) {
                                app.classList.add('scrolled--mobile')
                            }
                            if (app.classList.contains('scrolled-up--mobile')) {
                                app.classList.remove('scrolled-up--mobile')
                            }
                            else if (scrollPosY < 76) {
                                app.classList.remove('scrolled--mobile')
                            }
                        }
                    }

                    if (this.scroll_direction === 'up') {
                        if (app.classList.contains('scrolled--mobile')) {
                            app.classList.remove('scrolled--mobile')
                            app.classList.add('scrolled-up--mobile')
                        }
                    }

                    if (scrollPosY <= height) {
                        app.classList.remove('scrolled')
                        app.classList.remove('scrolled-up')
                    }

                    if (scrollPosY <= 0) {
                        app.classList.remove('scrolled--mobile')
                        app.classList.remove('scrolled-up--mobile')
                    }

                }
            }
        },
        computed: {
            ...mapState({
                isFilterVisible: (state) => state.categoryFilter.isVisible,
                isSorterVisible: (state) => state.categorySort.isVisible,
                isMenuVisible: (state) => state.menu.isVisible,
                isBasketVisible: (state) => state.cart.isVisible,
                isMapVisible: (state) => state.app.isMapVisible,
                isOrderSlideInVisible: (state) => state.account.isOrderSlideInVisible,
                showLogin: (state) => state.account.showLogin,
                isReseller: (state) => state.cart.cart.isReseller,
                isLoggedIn: (state) => state.cart.cart.isLoggedIn,
                cartQty: (state) => state.cart.qty,
                sortDir: (state) => state.categorySort.direction,
            }),
            ...mapGetters({
                showQty: 'cart/showQty'
            })
        },
        watch: {
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        beforeMount: function () {
            store.commit('app/setTranslations', window.siteData.translations)
            this.$store.dispatch('menu/close')
            this.$store.dispatch('cart/close')
            this.$store.dispatch('categorySort/close')
            this.$store.dispatch('account/closeOrder')

            //Get sitedata
            axios
                .get('/api/qd/sitedata')
                .then((response) => {
                    let data = response.data
                    store.dispatch('cart/updateCart', data.cart)
                    store.commit('app/setCsrfToken', data.csrf)
                    store.commit('app/setSiteUrl', data.siteUrl)
                })
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$refs.productimagesLarge) {
                    const productimagesLarge = this.$refs.productimagesLarge.$swiper
                    const productimagesThumbnail = this.$refs.productimagesThumbnail.$swiper
                    productimagesLarge.controller.control = productimagesThumbnail
                    productimagesThumbnail.controller.control = productimagesLarge
                }
            })
        },
    });

    return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
