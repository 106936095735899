const state = () => ({
    scrollPosition: 0,
    csrfToken: '',
    isMapVisible: false,
    translations: [],
    siteUrl: ''
})

// Getters
const getters = {
    scrollPosition: (state, getters) => {
        return state.scrollPosition
    }
}

// Actions
const actions = {
}

// Mutations
const mutations = {
    setScrollPosition(state, position) {
        state.scrollPosition = position
    },
    setCsrfToken(state, token) {
        state.csrfToken = token
    },
    setMapVisibility(state, value) {
        state.isMapVisible = value
    },
    setTranslations(state, value) {
        state.translations = value
    },
    setSiteUrl(state, value) {
        state.siteUrl = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
