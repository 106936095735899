const state = () => ({
    cart: {
        'isLoggedIn': false,
        'isReseller': false,
        'lineItems': [],
        'totalQty': 0,
        'paymentCurrency': 'DKK',
        'lineItemsTotal': '0 DKK',
        'lineItemsSubTotal': '0 DKK',
        'priceExVat': '0 DKK',
        'priceInclVat': '0 DKK',
        'shippingCost': '0 DKK',
        'gatewayId': false
    },
    qty: 0,
    isVisible: false
})

// Getters
const getters = {
    showQty: (state, getters) => {
        if (state.qty > 0) {
            return true
        }

        return false
    }
}

// Actions
const actions = {
    updateCart({ commit, state }, cart) {
        commit('setCart', cart)
        commit('setQty', cart.totalQty)
    },
    open({ commit, state }) {
        commit('setVasketVisibility', true)
    },
    close({ commit, state }) {
        commit('setVasketVisibility', false)
    }
}

// Mutations
const mutations = {
    setQty(state, value) {
        state.qty = value
    },
    setCart(state, value) {
        // Vue.set(state, 'cart', value)
        state.cart = value
    },
    setVasketVisibility(state, value) {
        state.isVisible = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
