const state = () => ({
    isOrderSlideInVisible: false,
    orderNumber: null,
    showLogin: false,
})

// Getters
const getters = {

}
// Actions
const actions = {
    showOrder({ commit, state }, orderNumber) {
        commit('setOrderNumber', orderNumber)
        commit('setOrderSlideInVisibility', true)
    },
    closeOrder({ commit, state }) {
        commit('setOrderNumber', null)
        commit('setOrderSlideInVisibility', false)
    },
    openLogin({ commit, state }) {
        commit('setLoginVisbiity', true)
    },
    hideLogin({ commit, state }) {
        commit('setLoginVisbiity', false)
    }
}

// Mutations
const mutations = {
    setOrderNumber(state, orderNumber) {
        state.orderNumber = orderNumber
    },
    setOrderSlideInVisibility(state, value) {
        state.isOrderSlideInVisible = value
    },
    setLoginVisbiity(state, value) {
        state.showLogin = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
